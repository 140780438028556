import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
// import { LoginService } from '../../services/login.service';
import { Location } from '@angular/common';
@Component({
  selector: 'at-chubb-critical-message',
  templateUrl: './critical-message.component.html',
  styleUrls: ['./critical-message.component.scss'],
})
export class CriticalMessageComponent {
  @Output() closeMessage: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() criticalMessage: any;
  title = 'Important Messages';
  showMessage = true;
  constructor(
    /* private _loginService: LoginService */ private location: Location
  ) {
    this.showMessage = !this.location?.isCurrentPathEqualTo(
      '/terms-conditions'
    );
  }

  close() {
    this.closeMessage.emit(false);
  }

  navigateTo(link): void {
    if (link && link.length) {
      window.open(link, '_blank');
    }
  }
}
