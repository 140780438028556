import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import {
  AtChubbLoginActionsEnums,
  ForgotPasswordAction,
  ForgotPasswordSuccessAction,
  SomethingFailed,
} from '../actions/at-chubb-login-actions';
import { ApiError } from '../../models/api-errors';
import { of } from 'rxjs';
import { ForgotPasswordService } from '../../services/forgot-password.service';
import { ForgotPassword } from '../../models/forgot-password.model';

@Injectable()
export class ForgotPasswordEffects {
  constructor(
    private _action$: Actions,
    private _forgotPasswordService: ForgotPasswordService,
    private _store: Store
  ) {}

  forgotPassword$ = createEffect(
    () =>
      this._action$.pipe(
        ofType(AtChubbLoginActionsEnums.FORGOT_PASSWORD),
        concatMap((action: ForgotPasswordAction) =>
          this._forgotPasswordService
            .getResetPasswordLink(action.emailAddress)
            .pipe(
              map((forgotPassword: ForgotPassword) => {
                this._store.dispatch(
                  new ForgotPasswordSuccessAction(forgotPassword)
                );
              }),
              catchError((error: ApiError) =>
                of(
                  this._store.dispatch(
                    new SomethingFailed(error.code, action, error)
                  )
                )
              )
            )
        )
      ),
    { dispatch: false }
  );
}
