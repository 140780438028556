<header class="header">
  <h1 class="header__brand">
    <img src="assets/images/chubb-logo-header.svg" alt="CHUBB LOGO" [routerLink]="['/']" class="header__brand--logo">
    
  </h1>

  <div class="header__need-help-desktop otherpages" *ngIf="!isHelpPage && !showDropDown">
    <a [routerLink]="['/help-faq']" target="_blank">Need help?</a>
  </div>

  <div *ngIf="!isHelpPage && showDropDown" class="header__need-help-desktop">
    <div class="header__need-help-desktop-left">
      <a [routerLink]="['/help-faq']" target="_blank">Need help?</a>
    </div>

    <div class="header__need-help-desktop-right">
      <mat-form-field appearance="fill">
        <mat-select name="languagelist" [value]="selected.value" (selectionChange)="navigateTo($event)">
          <mat-select-trigger>
            <img class="flagimage" *ngIf="selected.imgPath" src="{{selected.imgPath}}" />
            {{ selected.viewValue }}
          </mat-select-trigger>
          <mat-option *ngFor="let language of languages" [value]="language.value">
            <img class="flagimage" *ngIf="language.imgPath" src="{{language.imgPath}}">
            <span>{{language.viewValue}}</span>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</header>