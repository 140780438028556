import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AemDataReadService {
  constructor(private http: HttpClient) {}

  // GAM-2148: This API is not required for angular app
  /* getCarouselData(): Observable<any> {
    const url = environment.backend + environment.uri.getCarousel;
    return this.http.get(url);
  } */

  getCriticalMessage(): Observable<any> {
    const url = environment.backend + environment.uri.getCriticalMsg;
    return this.http.get(url, { responseType: 'text' });
  }

  getIeCriticalMessage(): Observable<any> {
    const url = environment.backend + environment.uri.getCriticalMsg;
    return this.http.get(url, { responseType: 'text' });
  }
}
