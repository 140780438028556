import { Action, ActionReducer, INIT, UPDATE } from '@ngrx/store';
import { keys, pick } from 'lodash-es';
import {
  AT_CHUBB_LOGIN_STATE_KEY,
  AtChubbLoginState,
} from '../state/at-chubb-login.state';

export function sessionStorageReducer(
  reducer: ActionReducer<AtChubbLoginState>
): ActionReducer<AtChubbLoginState> {
  return function (state: AtChubbLoginState, action: Action) {
    const newState = reducer(state, action);
    if (action.type === INIT.toString() || action.type === UPDATE.toString()) {
      const initState = <AtChubbLoginState>(
        JSON.parse(sessionStorage.getItem(AT_CHUBB_LOGIN_STATE_KEY))
      );
      return { ...newState, ...initState };
    } else {
      sessionStorage.setItem(
        AT_CHUBB_LOGIN_STATE_KEY,
        JSON.stringify(pick(newState, keys(newState)))
      );
    }
    return newState;
  };
}
