<footer class="footer">
  <div class="spacer"></div>
  <div class="footer__content">
    <h1>Chubb. Insured.<sup>&trade;</sup></h1>
    <div class="footer__content--links">
      <span><a>&copy; {{year}} Chubb</a></span>

      <span><a href="https://www.chubb.com/us-en/terms-of-use.html" target="_blank">Terms of Use</a></span>

      <span><a href="https://www.chubb.com/us-en/online-privacy-policy.html" target="_blank">Privacy Policy</a></span>
      
      <span><a href="https://www.chubb.com/us-en/online-privacy-policy.html#CCPA-section" target="_blank">CA Privacy Policy</a></span>

      <span><a href="https://www.chubb.com/us-en/agents-brokers/producer-compensation.html" target="_blank">Producer Compensation Legal Disclosure</a></span>

      <span><a href="https://www.chubb.com/us-en/contact-us/contact-landing-page.html" target="_blank">Contact Us</a></span>

      <span><a href="https://www.chubb.com/content/dam/chubb-sites/chubb-com/us-en/global/global/documents/pdf/licensinginformation.pdf" target="_blank">Licensing Information</a></span>

      <span><a href="https://www.chubb.com/us-en/do-not-call-policy.html" target="_blank">Do Not Call Policy</a></span>

      <span><a href="https://www.chubb.com/us-en/tax-information-reporting-withholding.html" target="_blank">Tax Information Reporting</a></span>

      <span><a href="https://www.chubb.com/us-en/important-notices/ny-domestic-violence.html" target="_blank">NY Domestic Violence Notice</a></span>
    </div>
  </div>
  <div class="spacer"></div>
</footer>
