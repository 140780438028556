import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import {
  UserDetails,
  UserDetailsRawResponseModel,
} from '../models/user-details.model';
import { ApiError, ApiErrorCodes } from '../models/api-errors';
import { UserDetailsTransformation } from './transformation/user-details-transformation';
import { BasicInfo } from '../models/basic-info.model';
import { News } from '../models/news.model';
import { RequestAccess } from '../models/request-access.model';
import { RequestAccessAction } from '../store/actions/at-chubb-login-actions';
declare const require;
// eslint-disable-next-line @typescript-eslint/no-var-requires
const xml2js = require('xml2js');
@Injectable({
  providedIn: 'root',
})
export class UserDetailService {
  constructor(private _http: HttpClient) {}
  getUserDetails(userId: string): Observable<UserDetails> {
    return this._http
      .get<UserDetailsRawResponseModel>(
        environment.backend + environment.uri.userProfile,
        {
          headers: new HttpHeaders({
            UserId: userId,
            Flow: 'AD',
          }),
        }
      )
      .pipe(
        catchError((error) =>
          throwError(
            new ApiError(ApiErrorCodes.userDetailsError, {
              ...error,
            })
          )
        ),
        map((response) => UserDetailsTransformation(response))
      );
  }
  editBasicInfo(
    userId: string,
    firstName: string,
    lastName: string,
    phoneNumber: string,
    mobileNumber: string,
    emailAddress: string
  ): Observable<BasicInfo> {
    return this._http
      .put(environment.backend + environment.uri.updateUserProfile, {
        userId: userId,
        firstName: firstName,
        lastName: lastName,
        phoneNumber: phoneNumber,
        mobileNumber: mobileNumber,
        emailAddress: emailAddress,
        step: 'update-contact-info',
        flow: 'AD',
      })
      .pipe(
        catchError((error) =>
          throwError(
            new ApiError(ApiErrorCodes.userDetailsError, {
              ...error,
            })
          )
        ),
        map((response: BasicInfo) => response)
      );
  }

  requestAccess(payload) {
    return this._http
      .post(environment.backend + environment.uri.requestAccess, {
        AccessType: payload.AccessType,
        FirstName: payload.FirstName,
        LastName: payload.LastName,
        AgencyName: payload.AgencyName,
        EmailAddress: payload.EmailAddress,
        Phone: payload.Phone,
        PreferredProducerNumber: payload.PreferredProducerNumber,
        PolicyNumber: payload.PolicyNumber,
        flow: 'AD',
      })
      .pipe(
        catchError((error) =>
          throwError(
            new ApiError(ApiErrorCodes.userDetailsError, {
              ...error,
            })
          )
        ),
        map((response: RequestAccess) => response)
      );
  }
  accessList() {
    return this._http.get(environment.backend + environment.uri.accessTypeList);
  }
  getNews() {
    return this._http
      .get(environment.backend + environment.uri.getNews, {
        responseType: 'text',
      })
      .pipe(
        switchMap(async (xml) => await this.parseXmlToJson(xml)),
        catchError((error) =>
          throwError(
            new ApiError(ApiErrorCodes.userDetailsError, {
              ...error,
            })
          )
        ),
        map((response: News) => response)
      );
  }
  async parseXmlToJson(xml) {
    return await xml2js
      .parseStringPromise(xml, { explicitArray: false })
      .then((response) => response.rss.channel.item);
  }
  getOTPEmail(emailAddress: string) {
    return this._http.get(
      environment.backend + environment.uri.emailVerificationRequest,
      {
        headers: new HttpHeaders({
          emailAddress: emailAddress,
          applicationName: 'AtChubb',
          channel: 'email',
        }),
      }
    );
  }
  verifyOTP(emailAddress: string, userID: string, oldEmail: string) {
    return this._http.put(
      environment.backend + environment.uri.editEmail,
      null,
      {
        headers: new HttpHeaders({
          EmailAddress: emailAddress,
          UserID: userID,
          OldEmail: oldEmail,
        }),
      }
    );
  }
}
