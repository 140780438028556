import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, map, take } from 'rxjs/operators';
import { UserDetailService } from '../../services/user-detail.service';
import { Store } from '@ngrx/store';
import { UserDetails } from '../../models/user-details.model';
import {
  AtChubbLoginActionsEnums,
  GetNewsAction,
  GetNewsSuccessAction,
  GetOTPAction,
  GetOTPActionSuccess,
  LoadUserDetailsAction,
  LoadUserDetailsSuccessAction,
  OTPVerificationAction,
  OTPVerificationSuccessAction,
  RequestAccessAction,
  RequestAccessSuccessAction,
  SomethingFailed,
} from '../actions/at-chubb-login-actions';
import { ApiError } from '../../models/api-errors';
import { of } from 'rxjs';
import { News } from '../../models/news.model';
import { RequestAccess } from '../../models/request-access.model';

@Injectable()
export class UserDetailsEffects {
  constructor(
    private _action$: Actions,
    private _userDetailsService: UserDetailService,
    private _store: Store
  ) {}

  loadUserDetails$ = createEffect(
    () =>
      this._action$.pipe(
        ofType(AtChubbLoginActionsEnums.LOAD_USER_DETAILS),
        concatMap((action: LoadUserDetailsAction) =>
          this._userDetailsService.getUserDetails(action.userName).pipe(
            map((userDetails: UserDetails) => {
              this._store.dispatch(
                new LoadUserDetailsSuccessAction(userDetails)
              );
            }),
            catchError((error: ApiError) =>
              of(
                this._store.dispatch(
                  new SomethingFailed(error.code, action, error)
                )
              )
            )
          )
        )
      ),
    { dispatch: false }
  );

  getNews$ = createEffect(
    () =>
      this._action$.pipe(
        ofType(AtChubbLoginActionsEnums.GET_NEWS),
        take(1),
        concatMap((action: GetNewsAction) =>
          this._userDetailsService.getNews().pipe(
            map((response: News) => {
              this._store.dispatch(new GetNewsSuccessAction(response));
            }),
            catchError((error: ApiError) =>
              of(
                this._store.dispatch(
                  new SomethingFailed(error.code, action, error)
                )
              )
            )
          )
        )
      ),
    { dispatch: false }
  );

  requestAccess = createEffect(
    () =>
      this._action$.pipe(
        ofType(AtChubbLoginActionsEnums.REQUEST_ACCESS),
        concatMap((action: RequestAccessAction) =>
          this._userDetailsService.requestAccess(action).pipe(
            map((response: RequestAccess) => {
              this._store.dispatch(new RequestAccessSuccessAction(response));
            }),
            catchError((error: ApiError) =>
              of(
                this._store.dispatch(
                  new SomethingFailed(error.code, action, error)
                )
              )
            )
          )
        )
      ),
    { dispatch: false }
  );

  getOTPEmail$ = createEffect(
    () =>
      this._action$.pipe(
        ofType(AtChubbLoginActionsEnums.OTP_REQUEST),
        concatMap((action: GetOTPAction) =>
          this._userDetailsService.getOTPEmail(action.emailAddress).pipe(
            map((response) => {
              this._store.dispatch(new GetOTPActionSuccess());
            }),
            catchError((error: ApiError) =>
              of(
                this._store.dispatch(
                  new SomethingFailed(error.code, action, error)
                )
              )
            )
          )
        )
      ),
    { dispatch: false }
  );

  verifyOTP = createEffect(
    () =>
      this._action$.pipe(
        ofType(AtChubbLoginActionsEnums.OTP_VERIFICATION),
        concatMap((action: OTPVerificationAction) =>
          this._userDetailsService
            .verifyOTP(action.emailAddress, action.userName, action.oldEmail)
            .pipe(
              map((response) => {
                this._store.dispatch(new OTPVerificationSuccessAction());
              }),
              catchError((error: ApiError) =>
                of(
                  this._store.dispatch(
                    new SomethingFailed(error.code, action, error)
                  )
                )
              )
            )
        )
      ),
    { dispatch: false }
  );
}
