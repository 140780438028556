import { Component, OnInit } from '@angular/core';
import * as footerData from '../../../assets/common/config/footer.json';

@Component({
  selector: 'at-chubb-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  footer: any = (footerData as any).default;
  year = new Date().getFullYear();

  constructor() {}
}
