import { Component } from '@angular/core';
// import { delay, take } from 'rxjs/operators';
// import { LoginService } from './services/login.service';
import { environment } from 'src/environments/environment';
import * as FullStory from '@fullstory/browser';
// import { OutageResponseModel } from './models/outage-response.model';

@Component({
  selector: 'at-chubb-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  tokenSubs;
  outageSubs;
  a2aToken;
  outageFallbackMessage = '';
  outageTimer;

  fullStoryEnvironments = {
    test: '18MHDY',
    production: '18MHGA',
  };

  // constructor(private loginService: LoginService) {
  constructor() {
    // set up FullStory
    let orgIdString = '';
    // pass the correct orgID to FS, per environment
    if (environment.production === true) {
      orgIdString = this.fullStoryEnvironments.production;
    } else {
      orgIdString = this.fullStoryEnvironments.test;
    }
    // FullStory.init({ orgId: orgIdString, devMode: !environment.production });  // enabled only in production
    FullStory.init({ orgId: orgIdString, devMode: false }); // enabling everywhere for testing purposes

    // GAM-990: OutageFallback, OutageToken & OutageMsg APIs are not required.
    /* this.loginService.getOutageMessageFallback().subscribe((response) => {
      if (response) {
        this.outageFallbackMessage = response['outageMessage'];
      }
    });

    this.tokenSubs = this.loginService.getOutageToken().subscribe(
      (response) => {
        if (response) {
          this.a2aToken = response.access_token;
          this.checkForOutage();
        }
      },
      (error) => {
        this.loginService.outageResponseObject = undefined;
        this.loginService.setOutageReceived(true);
      }
    ); */
  }

  // ngOnInit(): void {}

  // GAM-990: OutageFallback, OutageToken & OutageMsg APIs are not required.
  /* checkForOutage() {
    // start timer for fallback
    const outageFallbackTime =
      environment.uri.currentOutage.fallbackMessageTime; // comes in seconds
    this.outageTimer = setTimeout(() => {
      this.outageSubs.unsubscribe();
      const fallbackObject: OutageResponseModel = {
        status: '',
        statusMessage: '',
        outageDetails: [
          {
            outageMessage: this.outageFallbackMessage,
          },
        ],
      };
      this.loginService.outageResponseObject = fallbackObject;
      this.loginService.setOutageReceived(true);
    }, outageFallbackTime * 1000);

    this.outageSubs = this.loginService
      .getCurrentOutage(this.a2aToken)
      .subscribe(
        (response) => {
          if (response) {
            clearTimeout(this.outageTimer);
            if (response.status === 'S') {
              this.loginService.outageResponseObject = response;
              this.loginService.setOutageReceived(true);
            } else if (response.status === 'E') {
              this.loginService.outageResponseObject = undefined;
              this.loginService.setOutageReceived(true);
            }
          }
        },
        (error) => {
          clearTimeout(this.outageTimer);
          this.loginService.outageResponseObject = undefined;
          this.loginService.setOutageReceived(true);
        }
      );
  } */

  /* ngOnDestroy() {
    this.tokenSubs.unsubscribe();
    this.outageSubs.unsubscribe();
  } */
}
