import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { ApiError, ApiErrorCodes } from '../models/api-errors';
import { ResetPassword } from '../models/reset-password.model';
import { ForgotPassword } from '../models/forgot-password.model';

@Injectable({
  providedIn: 'root',
})
export class ForgotPasswordService {
  constructor(private _http: HttpClient) {}
  getResetPasswordLink(emailAddress: string): Observable<ForgotPassword> {
    return this._http
      .get(environment.backend + environment.uri.sendPasswordLink, {
        headers: new HttpHeaders({
          EmailAddress: emailAddress,
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache',
          flow: 'AD',
        }),
      })
      .pipe(
        catchError((error) =>
          throwError(
            new ApiError(ApiErrorCodes.userDetailsError, {
              ...error,
            })
          )
        ),
        map((response: ForgotPassword) => response)
      );
  }
  getResetPasswordLinkforMultipleUsers(
    emailAddress: string,
    userId: string
  ): Observable<ForgotPassword> {
    return this._http
      .get(environment.backend + environment.uri.sendPasswordLink, {
        headers: new HttpHeaders({
          EmailAddress: emailAddress,
          UserId: userId,
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache',
          flow: 'AD',
        }),
      })
      .pipe(
        catchError((error) =>
          throwError(
            new ApiError(ApiErrorCodes.userDetailsError, {
              ...error,
            })
          )
        ),
        map((response: ForgotPassword) => response)
      );
  }
  getResetPasswordLinkforexpiredUser(
    emailAddress: string,
    userId: string,
    IsSixmonthsInactivityflow: string
  ): Observable<ForgotPassword> {
    return this._http
      .get(environment.backend + environment.uri.sendPasswordLink, {
        headers: new HttpHeaders({
          EmailAddress: emailAddress,
          UserId: userId,
          IsSixMonthsInActivityFlow: IsSixmonthsInactivityflow,
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache',
          flow: 'AD',
        }),
      })
      .pipe(
        catchError((error) =>
          throwError(
            new ApiError(ApiErrorCodes.userDetailsError, {
              ...error,
            })
          )
        ),
        map((response: ForgotPassword) => response)
      );
  }
  setPassword(userId: string, newPassword: string): Observable<ResetPassword> {
    console.log('set password call');
    return this._http
      .put(environment.backend + environment.uri.setPassword, {
        userId: userId,
        newPassword: btoa(newPassword),
        step: '10',
        flow: 'AD',
      })
      .pipe(
        catchError((error) =>
          throwError(
            new ApiError(ApiErrorCodes.setPasswordError, {
              ...error,
            })
          )
        ),
        map((response: ResetPassword) => response)
      );
  }
}
