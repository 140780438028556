import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import {
  AtChubbLoginActionsEnums,
  ResetPasswordAction,
  ResetPasswordSuccessAction,
  SomethingFailed,
} from '../actions/at-chubb-login-actions';
import { ApiError } from '../../models/api-errors';
import { of } from 'rxjs';
import { ForgotPasswordService } from '../../services/forgot-password.service';
import { ResetPassword } from '../../models/reset-password.model';

@Injectable()
export class ResetPasswordEffects {
  constructor(
    private _action$: Actions,
    private _forgotPasswordService: ForgotPasswordService,
    private _store: Store
  ) {}

  resetPassword$ = createEffect(
    () =>
      this._action$.pipe(
        ofType(AtChubbLoginActionsEnums.RESET_PASSWORD),
        concatMap((action: ResetPasswordAction) =>
          this._forgotPasswordService
            .setPassword(action.userName, action.newPassword)
            .pipe(
              map((resetPasswordDetails: ResetPassword) => {
                this._store.dispatch(
                  new ResetPasswordSuccessAction(resetPasswordDetails)
                );
              }),
              catchError((error: ApiError) =>
                of(
                  this._store.dispatch(
                    new SomethingFailed(error.code, action, error)
                  )
                )
              )
            )
        )
      ),
    { dispatch: false }
  );
}
