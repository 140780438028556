import {
  aemDataActionType,
  AemDataReadActionsEnums,
} from '../actions/aem-data-read-action';

export const IeCriticalmsgDatastate = '';

export function setIeCriticalmsgDataReducer(
  setIeCriticalmsgDataState = IeCriticalmsgDatastate,
  action: aemDataActionType
) {
  switch (action.type) {
    case AemDataReadActionsEnums.LOAD_IECRITICAL_MESSAGE: {
      setIeCriticalmsgDataState = action.payload;
      return setIeCriticalmsgDataState;
    }
    default: {
      return setIeCriticalmsgDataState;
    }
  }
}
