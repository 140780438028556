import { AtChubbLoginState } from '../state/at-chubb-login.state';
import { BasicInfoSuccessAction } from '../actions/at-chubb-login-actions';

export function basicInfoSuccessReducer(
  currentState: AtChubbLoginState,
  action: BasicInfoSuccessAction
): AtChubbLoginState {
  return {
    ...currentState,
    basicInfo: action.basicInfo,
  };
}
