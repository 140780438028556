import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { environment } from '../environments/environment';
import { RedirectGuard } from './guards/redirect.guard';

const routes: Routes = [
  {
    path: 'forgot-user',
    loadChildren: () =>
      import('./components/forgot-user/forgot-user.module').then(
        (m) => m.ForgotUserModule
      ),
  },
  {
    path: 'forgot-password',
    loadChildren: () =>
      import('./components/forgot-password/forgot-password.module').then(
        (m) => m.ForgotPasswordModule
      ),
  },
  {
    path: 'expired-link',
    loadChildren: () =>
      import('./components/expired-link/expired-link.module').then(
        (m) => m.ExpiredLinkModule
      ),
  },
  {
    path: 'en_US',
    loadChildren: () =>
      import(
        './components/terms-and-conditions-root/terms-and-conditions-root.module'
      ).then((m) => m.TermsAndConditionsRootModule),
  },
  {
    path: 'terms-conditions',
    loadChildren: () =>
      import('./components/terms-conditions/terms-conditions.module').then(
        (m) => m.TermsConditionsModule
      ),
  },
  {
    path: 'reset-password-confirmation',
    loadChildren: () =>
      import(
        './components/reset-password-confirmation/reset-password-confirmation.module'
      ).then((m) => m.ResetPasswordConfirmationModule),
  },
  {
    path: 'update-password-confirmation',
    loadChildren: () =>
      import(
        './components/update-password-confirmation/update-password-confirmation.module'
      ).then((m) => m.UpdatePasswordConfirmationModule),
  },
  {
    path: 'request-access',
    loadChildren: () =>
      import('./components/request-access/request-access.module').then(
        (m) => m.RequestAccessModule
      ),
  },
  {
    path: 'get-started',
    loadChildren: () =>
      import('./components/get-started/get-started.module').then(
        (m) => m.GetStartedModule
      ),
  },
  {
    path: 'set-password',
    loadChildren: () =>
      import('./components/set-password/set-password.module').then(
        (m) => m.SetPasswordModule
      ),
  },
  {
    path: 'update-password',
    loadChildren: () =>
      import('./components/update-password/update-password.module').then(
        (m) => m.UpdatePasswordModule
      ),
  },
  {
    path: 'set-expired-password',
    loadChildren: () =>
      import(
        './components/set-expired-password/set-expired-password.module'
      ).then((m) => m.SetExpiredPasswordModule),
  },
  {
    path: 'confirm-contact',
    loadChildren: () =>
      import('./components/confirm-contact/confirm-contact.module').then(
        (m) => m.ConfirmContactModule
      ),
  },
  {
    path: 'help-faq',
    loadChildren: () =>
      import('./components/help-faq/help-faq.module').then(
        (m) => m.HelpFaqModule
      ),
  },
  {
    path: 'contact-us',
    loadChildren: () =>
      import('./components/contact-us-modal/contact-us-modal.module').then(
        (m) => m.ContactUsModalModule
      ),
  },
  {
    path: 'request-access-details',
    loadChildren: () =>
      import(
        './components/request-access-details/request-access-details.module'
      ).then((m) => {
        return m.RequestAccessDetailsModule;
      }),
  },
  {
    path: '',
    canActivate: [RedirectGuard],
    component: RedirectGuard,
    data: {
      externalUrl: environment.wildCardRoute,
    },
  },
  {
    path: '**',
    canActivate: [RedirectGuard],
    component: RedirectGuard,
    data: {
      externalUrl: environment.wildCardRoute,
    },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
