<div class="main__wrapper" *ngIf="loading"
  style="background-image: url(../../../assets/images/login-background.jpg); background-size: cover; background-repeat: no-repeat; background-position: top right;">
  <at-chubb-unsupported-browser  *ngIf="unsupportedBrowser"></at-chubb-unsupported-browser>
  <at-chubb-critical-message 
  (closeMessage)="onNotify($event)" 
  *ngIf="displayCriticalMessage && criticalMessagePresent"
  [criticalMessage]="criticalMessage"
  ></at-chubb-critical-message>
  <div class="wrapper__content">
    <div class="spacer-white"></div>
    <div class="wrapper__component">
      <div class="wrapper__component-content">
        <at-chubb-header></at-chubb-header>
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
  <at-chubb-footer></at-chubb-footer>
</div>
