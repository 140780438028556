import {
  aemDataActionType,
  AemDataReadActionsEnums,
} from '../actions/aem-data-read-action';

export const criticalmsgDatastate = '';

export function setCriticalmsgDataReducer(
  setCriticalmsgDataState = criticalmsgDatastate,
  action: aemDataActionType
) {
  switch (action.type) {
    case AemDataReadActionsEnums.LOAD_CRITICAL_MESSAGE: {
      setCriticalmsgDataState = action.payload;
      return setCriticalmsgDataState;
    }
    default: {
      return setCriticalmsgDataState;
    }
  }
}
