import {
  aemDataActionType,
  AemDataReadActionsEnums,
} from '../actions/aem-data-read-action';

export const aemDatastate = '';

export function setaemDataReducer(
  setAemDataState = aemDatastate,
  action: aemDataActionType
) {
  switch (action.type) {
    case AemDataReadActionsEnums.LOAD_AEMDATA: {
      setAemDataState = action.payload;
      return setAemDataState;
    }
    default: {
      return setAemDataState;
    }
  }
}
