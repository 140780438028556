import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { ForgotUser } from '../models/forgot-user.model';
import { catchError, map } from 'rxjs/operators';
import { ApiError, ApiErrorCodes } from '../models/api-errors';

@Injectable({
  providedIn: 'root',
})
export class ForgotUserService {
  constructor(private _http: HttpClient) {}
  getUsername(emailAddress: string): Observable<ForgotUser> {
    return this._http
      .get(environment.backend + environment.uri.sendUserId, {
        headers: new HttpHeaders({
          EmailAddress: emailAddress,
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache',
          Flow: 'AD',
        }),
      })
      .pipe(
        catchError((error) =>
          throwError(
            new ApiError(ApiErrorCodes.userDetailsError, {
              ...error,
            })
          )
        ),
        map((response: ForgotUser) => response)
      );
  }
}
