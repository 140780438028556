import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'at-chubb-unsupported-browser',
  templateUrl: './unsupported-browser.component.html',
  styleUrls: ['./unsupported-browser.component.scss'],
})
export class UnsupportedBrowserComponent {
  constructor() {}
}
