import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  AT_CHUBB_LOGIN_STATE_KEY,
  AtChubbLoginState,
} from '../state/at-chubb-login.state';
import { Observable } from 'rxjs';
import { map, skipWhile, withLatestFrom } from 'rxjs/operators';
import { UserDetails } from '../../models/user-details.model';
import { isNil } from 'lodash-es';
import { News } from '../../models/news.model';
import { ResetPassword } from '../../models/reset-password.model';
import { BasicInfo } from '../../models/basic-info.model';

@Injectable()
export class AtChubbLoginSelectors {
  constructor(private _store: Store<AtChubbLoginState>) {}

  getStateSnapshot(): Observable<AtChubbLoginState> {
    return this._store
      .select((state) => state[AT_CHUBB_LOGIN_STATE_KEY])
      .pipe(withLatestFrom((state) => state));
  }
  getState(): Observable<AtChubbLoginState> {
    return this.getStateSnapshot();
  }
  getUserDetails(): Observable<UserDetails> {
    return this.getState().pipe(
      skipWhile((state) => isNil(state.userDetails)),
      map((state: any) => state.userDetails)
    );
  }
  getNews(): Observable<News> {
    return this.getState().pipe(
      skipWhile((state) => isNil(state.news)),
      map((state: any) => state.news)
    );
  }
  setPassword(): Observable<ResetPassword> {
    return this.getState().pipe(
      skipWhile((state) => isNil(state.setPassword)),
      map((state: any) => state.setPassword)
    );
  }
  getBasicInfo(): Observable<BasicInfo> {
    return this.getState().pipe(
      skipWhile((state) => isNil(state.basicInfo)),
      map((state: any) => state.basicInfo)
    );
  }
}
