import { AtChubbLoginState } from '../state/at-chubb-login.state';
import { GetNewsSuccessAction } from '../actions/at-chubb-login-actions';

export function newsSuccessReducer(
  currentState: AtChubbLoginState,
  action: GetNewsSuccessAction
): AtChubbLoginState {
  return {
    ...currentState,
    news: action.news,
  };
}
