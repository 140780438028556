import { Action } from '@ngrx/store';
import { UserDetails } from '../../models/user-details.model';
import { ApiErrorCodes } from '../../models/api-errors';
import {ResetPassword} from '../../models/reset-password.model';
import {BasicInfo} from '../../models/basic-info.model';
import {ForgotUser} from '../../models/forgot-user.model';
import {ForgotPassword} from '../../models/forgot-password.model';
import { News } from '../../models/news.model';
import { RequestAccess } from '../../models/request-access.model';

export enum AtChubbLoginActionsEnums {
  LOAD_USER_DETAILS = '[USER] LOAD USER DETAILS',
  LOAD_USER_DETAILS_SUCCESS = '[USER] LOAD USER DETAILS SUCCESS',
  RESET_PASSWORD = '[USER] RESET PASSWORD',
  RESET_PASSWORD_SUCCESS = '[USER] RESET PASSWORD SUCCESS',
  BASIC_INFO = '[USER] BASIC INFO',
  BASIC_INFO_SUCCESS = '[USER] BASIC INFO SUCCESS',
  FORGOT_USER = '[USER] FORGOT USER',
  FORGOT_USER_SUCCESS = '[USER] FORGOT USER SUCCESS',
  FORGOT_PASSWORD = '[USER] FORGOT PASSWORD',
  FORGOT_PASSWORD_SUCCESS = '[USER] FORGOT PASSWORD SUCCESS',
  GET_NEWS = '[USER] GET NEWS',
  GET_NEWS_SUCCESS = '[USER] GET NEWS SUCCESS',
  REQUEST_ACCESS = '[USER] REQUEST ACCESS',
  REQUEST_ACCESS_SUCCESS = '[USER] REQUEST ACCESS SUCCESS',
  OTP_REQUEST = '[USER] OTP REQUEST',
  OTP_REQUEST_SUCCESS = '[USER] OTP REQUEST SUCCESS',
  OTP_VERIFICATION = '[USER] OTP VERIFICATION',
  OTP_VERIFICATION_SUCCESS = '[USER] OTP VERIFICATION SUCCESS',
  SOMETHING_FAILED = '[USER] SOMETHING FAILED',
}
export class LoadUserDetailsAction implements Action {
  readonly type: string = AtChubbLoginActionsEnums.LOAD_USER_DETAILS;
  constructor(public userName: string) {}
}
export class LoadUserDetailsSuccessAction implements Action {
  readonly type: string = AtChubbLoginActionsEnums.LOAD_USER_DETAILS_SUCCESS;
  constructor(public userDetails: UserDetails) {}
}
export class ResetPasswordAction implements Action {
  readonly type: string = AtChubbLoginActionsEnums.RESET_PASSWORD;
  constructor(public userName: string, public newPassword: string) {}
}
export class ResetPasswordSuccessAction implements Action {
  readonly type: string = AtChubbLoginActionsEnums.RESET_PASSWORD_SUCCESS;
  constructor(public resetPasswordResponse: ResetPassword) {}
}
export class BasicInfoAction implements Action {
  readonly type: string = AtChubbLoginActionsEnums.BASIC_INFO;
  constructor(public userName: string, public firstName: string, public lastName: string, public phoneNumber: string, public mobileNumber: string, public emailAddress: string) {}
}
export class BasicInfoSuccessAction implements Action {
  readonly type: string = AtChubbLoginActionsEnums.BASIC_INFO_SUCCESS;
  constructor(public basicInfo: BasicInfo) {}
}
export class ForgotUserAction implements Action {
  readonly type: string = AtChubbLoginActionsEnums.FORGOT_USER;
  constructor(public emailAddress: string) {}
}
export class ForgotUserSuccessAction implements Action {
  readonly type: string = AtChubbLoginActionsEnums.FORGOT_USER_SUCCESS;
  constructor(public forgotUser: ForgotUser) {}
}
export class ForgotPasswordAction implements Action {
  readonly type: string = AtChubbLoginActionsEnums.FORGOT_PASSWORD;
  constructor(public emailAddress: string) {}
}
export class ForgotPasswordSuccessAction implements Action {
  readonly type: string = AtChubbLoginActionsEnums.FORGOT_PASSWORD_SUCCESS;
  constructor(public forgotUser: ForgotPassword) {}
}
export class GetNewsAction implements Action {
  readonly type: string = AtChubbLoginActionsEnums.GET_NEWS;
  constructor() {}
}
export class GetNewsSuccessAction implements Action {
  readonly type: string = AtChubbLoginActionsEnums.GET_NEWS_SUCCESS;
  constructor(public news: News) {}
}
export class RequestAccessAction implements Action {
  readonly type: string = AtChubbLoginActionsEnums.REQUEST_ACCESS;

  constructor(public requestAccess: { AgencyName: string; PreferredProducerNumber: number; PolicyNumber: string; FirstName: string; Phone: string; LastName: string; AccessType: string; EmailAddress: string }) {}
}
export class RequestAccessSuccessAction implements Action {
  readonly type: string = AtChubbLoginActionsEnums.REQUEST_ACCESS_SUCCESS;
  constructor(public response: RequestAccess) {}
}
export class GetOTPAction implements Action {
  readonly type: string = AtChubbLoginActionsEnums.OTP_REQUEST;
  constructor(public emailAddress: string) {}
}
export class GetOTPActionSuccess implements Action {
  readonly type: string = AtChubbLoginActionsEnums.OTP_REQUEST_SUCCESS;
  constructor() {}
}
export class OTPVerificationAction implements Action {
  readonly type: string = AtChubbLoginActionsEnums.OTP_VERIFICATION;
  constructor(public emailAddress: string, public userName: string, public oldEmail: string) {}
}
export class OTPVerificationSuccessAction implements Action {
  readonly type: string = AtChubbLoginActionsEnums.OTP_VERIFICATION_SUCCESS;
  constructor() {}
}
export class SomethingFailed implements Action {
  readonly type: string = AtChubbLoginActionsEnums.SOMETHING_FAILED;
  constructor(
    public readonly errorCode: ApiErrorCodes,
    public readonly action?: AtChubbLoginActionsTypes,
    public readonly errorDetails?: any
  ) {}
}
export type AtChubbLoginActionsTypes =
  | LoadUserDetailsAction
  | LoadUserDetailsSuccessAction
  | ResetPasswordAction
  | ResetPasswordSuccessAction
  | BasicInfoAction
  | BasicInfoSuccessAction
  | ForgotUserAction
  | ForgotUserSuccessAction
  | ForgotPasswordAction
  | ForgotPasswordSuccessAction
  | GetNewsAction
  | GetNewsSuccessAction
  | RequestAccessAction
  | RequestAccessSuccessAction
  | OTPVerificationAction
  | OTPVerificationSuccessAction
  | GetOTPAction
  | GetOTPActionSuccess
  | SomethingFailed;
