import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import {
  Router,
  RouterEvent,
  Event,
  NavigationEnd,
  ActivatedRoute,
} from '@angular/router';
import { Location } from '@angular/common';
import { filter } from 'rxjs/operators';
import { LanguageDropdownModel } from '../../models/language-dropdown.model';
import * as languageSelection from '../languagedropdown-modal/languageSelection.json';
import { environment } from '../../../environments/environment';
@Component({
  selector: 'at-chubb-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, AfterViewInit {
  isHelpPage = false;
  showDropDown = false;
  languages: any = (languageSelection as any).default;
  selected: LanguageDropdownModel;
  constructor(
    private location: Location,
    private router: Router,
    private changeDetect: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.isHelpPage = event.url === '/help-faq';
        if (event.url === '/') {
          this.showDropDown = true;
        } else {
          this.showDropDown = false;
          this.isshowDropDown(event.url);
        }
      }
    });
    this.selected = this.languages[1];
  }

  ngAfterViewInit() {
    if (document.getElementById('login')) {
      this.showDropDown = true;
    }
    this.changeDetect.detectChanges();
  }

  isshowDropDown(url: string): void {
    if (url.includes('GAMsg')) {
      this.showDropDown = true;
    } else {
      this.showDropDown = false;
    }
  }

  navigateTo(event) {
    this.selected = null;
    if (event.value === 'default') {
      this.selected = this.languages[1];
    } else {
      const newSelected = this.languages.filter(
        (item) => item.value === event.value
      );
      this.selected = newSelected[0];
      const url = environment.baseUrl + this.selected.value;
      window.location.href = url;
    }
  }
}
