import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import {
  AtChubbLoginActionsEnums,
  ForgotUserAction,
  ForgotUserSuccessAction,
  SomethingFailed,
} from '../actions/at-chubb-login-actions';
import { ApiError } from '../../models/api-errors';
import { of } from 'rxjs';
import { ForgotUserService } from '../../services/forgot-user.service';
import { ForgotUser } from '../../models/forgot-user.model';

@Injectable()
export class ForgotUserEffects {
  constructor(
    private _action$: Actions,
    private _forgotUserService: ForgotUserService,
    private _store: Store
  ) {}

  forgotUser$ = createEffect(
    () =>
      this._action$.pipe(
        ofType(AtChubbLoginActionsEnums.FORGOT_USER),
        concatMap((action: ForgotUserAction) =>
          this._forgotUserService.getUsername(action.emailAddress).pipe(
            map((forgotUser: ForgotUser) => {
              this._store.dispatch(new ForgotUserSuccessAction(forgotUser));
            }),
            catchError((error: ApiError) =>
              of(
                this._store.dispatch(
                  new SomethingFailed(error.code, action, error)
                )
              )
            )
          )
        )
      ),
    { dispatch: false }
  );
}
