import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, map, take, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import {
  AtChubbLoginActionsEnums,
  BasicInfoAction,
  BasicInfoSuccessAction,
  LoadUserDetailsAction,
  LoadUserDetailsSuccessAction,
  SomethingFailed,
} from '../actions/at-chubb-login-actions';
import { ApiError } from '../../models/api-errors';
import { of } from 'rxjs';
import { UserDetailService } from '../../services/user-detail.service';
import { BasicInfo } from '../../models/basic-info.model';
import { UserDetails } from '../../models/user-details.model';

@Injectable()
export class BasicInfoEffects {
  constructor(
    private _action$: Actions,
    private _userDetailsService: UserDetailService,
    private _store: Store
  ) {}

  basicInfo$ = createEffect(
    () =>
      this._action$.pipe(
        ofType(AtChubbLoginActionsEnums.BASIC_INFO),
        take(1),
        concatMap((action: BasicInfoAction) =>
          this._userDetailsService
            .editBasicInfo(
              action.userName,
              action.firstName,
              action.lastName,
              action.phoneNumber,
              action.mobileNumber,
              action.emailAddress
            )
            .pipe(
              map((basicInfo: BasicInfo) => {
                this._store.dispatch(new BasicInfoSuccessAction(basicInfo));
              }),
              tap(() => {
                this._store.dispatch(
                  new LoadUserDetailsAction(action.userName)
                );
              }),
              catchError((error: ApiError) =>
                of(
                  this._store.dispatch(
                    new SomethingFailed(error.code, action, error)
                  )
                )
              )
            )
        )
      ),
    { dispatch: false }
  );

  loadUserSuccess$ = createEffect(
    () =>
      this._action$.pipe(
        ofType(AtChubbLoginActionsEnums.LOAD_USER_DETAILS),
        take(1),
        map((userDetails: UserDetails) => {
          this._store.dispatch(new LoadUserDetailsSuccessAction(userDetails));
        })
      ),
    { dispatch: false }
  );
}
