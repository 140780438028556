import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import {
  Router,
  RouterEvent,
  Event,
  NavigationEnd,
  ActivatedRoute,
} from '@angular/router';
import { filter } from 'rxjs/operators';
import { WrapperService } from '../../services/wrapper.service';
import { select, Store } from '@ngrx/store';
import { LoadAemDataAction } from 'src/app/store/actions/aem-data-read-action';
@Component({
  selector: 'at-chubb-wrapper',
  templateUrl: './wrapper.component.html',
  styleUrls: ['./wrapper.component.scss'],
})
export class WrapperComponent implements OnInit {
  route: any;
  loading: boolean;
  displayCriticalMessage: boolean;
  unsupportedBrowser = false;
  criticalMessagePresent = false;
  browserSupportIE = false;
  aemStoreData: string;
  getCarouselData: any;
  getCriticalMsg: any;
  getIeCriticalMsg: any;
  criticalMessage: any;

  constructor(
    private location: Location,
    private router: Router,
    private _route: ActivatedRoute,
    private wrapperService: WrapperService,
    private _store: Store<{
      aemStoreData: string;
      getCarouselData: any;
      getCriticalMsg: any;
      getIeCriticalMsg: any;
    }>
  ) {
    this.readDataFromStore();
    this.router.events
      .pipe(filter((event: Event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        const queryParam = this._route.snapshot.queryParamMap.get('GAURI');
        if (event.url === '/') {
          this.displayCriticalMessage = true;
        } else {
          this.displayCriticalMessage = !!queryParam;
        }
      });
  }

  ngOnInit(): void {
    this.loading = false;
    this.getUrl();
    this.detectIEBrowser();
    this.getMicrosoftEdgeBrowserVersion();
    if (this.isIE() || this.browserSupportIE || this.isOldEdge()) {
      this.unsupportedBrowser = true;
    }
    // this.checkCriticalMessageContent();
  }

  readDataFromStore(): void {
    this._store.pipe(select('aemStoreData')).subscribe((isAemData) => {
      if (!isAemData) {
        this._store.dispatch(new LoadAemDataAction('readData'));
      }
    });
    this._store.pipe(select('getCriticalMsg')).subscribe((criticalMsg) => {
      if (criticalMsg.length) {
        this.criticalMessagePresent = true;
        this.displayCriticalMessage = true;
        this.criticalMessage = criticalMsg;
      }
    });
    // this._store
    // .pipe(select('getIeCriticalMsg'))
    // .subscribe((ieCriticalMsg) => {
    //   if (ieCriticalMsg) {
    //     // console.log('ieCriticalMsg >>', ieCriticalMsg);
    //   }
    // });
  }

  getUrl() {
    this.router.events.subscribe((val) => {
      if (this.location.path() !== '') {
        this.route = this.location.path();
      }
      this.loading = true;
    });
  }

  onNotify(close: boolean): void {
    this.displayCriticalMessage = close;
  }

  isIE() {
    return /MSIE|Trident/.test(window.navigator.userAgent);
  }

  detectIEBrowser() {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf('MSIE');
    const trident = ua.indexOf('Trident/');
    const edge = ua.indexOf('Edg');
    if (msie > 0 || trident > 0) {
      // IE 10 or older
      this.browserSupportIE = true;
    } else if (edge > -1) {
      const edgeVersion = this.getMicrosoftEdgeBrowserVersion();
      if (parseInt(edgeVersion, 10) < 79) {
        this.browserSupportIE = true;
      }
    }
  }

  getMicrosoftEdgeBrowserVersion() {
    let version = null;
    const userAgent = navigator.userAgent.toLowerCase();
    const matches = userAgent.match(
      /edge\/([0-9]+\.[0-9]|\.[0-9]|\.[0-9]+)|edg\/([0-9]+\.[0-9]|\.[0-9]|\.[0-9]+)/
    );
    if (matches) {
      version = matches[1];
    }
    return version;
  }

  isOldEdge() {
    function isMicrosoftEdgeBrowser(userAgent) {
      if (
        userAgent.indexOf('chrome') !== -1 &&
        userAgent.indexOf('edg') !== -1
      ) {
        return true;
      } else {
        return false;
      }
    }

    function getMicrosoftEdgeBrowserVersion() {
      let version = null;
      const userAgent = navigator.userAgent.toLowerCase();
      if (isMicrosoftEdgeBrowser(userAgent)) {
        const matches = userAgent.match(
          /edge\/([0-9]+\.[0-9]|\.[0-9]|\.[0-9]+)|edg\/([0-9]+\.[0-9]|\.[0-9]|\.[0-9]+)/
        );
        if (matches) {
          version = matches[1];
        }
      }
      return version;
    }

    if (getMicrosoftEdgeBrowserVersion()) {
      return getMicrosoftEdgeBrowserVersion() < 80;
    }
  }

  // checkCriticalMessageContent() {
  //   this.wrapperService.getCriticalMessage().subscribe(
  //     (response: any) => {
  //       if (this.wrapperService.criticalMessagePresent) {
  //         this.criticalMessagePresent = true;
  //       }
  //     },
  //     (error) => {
  //       console.error(error);
  //     }
  //   );
  // }
}
