import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class WrapperService {
  criticalMessagePresent = false;

  constructor(private _http: HttpClient) {}

  getCriticalMessage() {
    return this._http.get(environment.pmsg, { responseType: 'text' }).pipe(
      map((response) => {
        if (response !== '' && response.length > 0) {
          this.criticalMessagePresent = true;
        }
      }),
      catchError(async (error) => console.error(error))
    );
  }
}
