import {
  aemDataActionType,
  AemDataReadActionsEnums,
} from '../actions/aem-data-read-action';

export const carouselDatastate = '';

export function setCarouselDataReducer(
  setCarouselDataState = carouselDatastate,
  action: aemDataActionType
) {
  switch (action.type) {
    case AemDataReadActionsEnums.LOAD_CAROUSEL_DETAILS: {
      setCarouselDataState = action.payload;
      return setCarouselDataState;
    }
    default: {
      return setCarouselDataState;
    }
  }
}
