import { UserDetails } from '../../models/user-details.model';
import { News } from '../../models/news.model';
import { ResetPassword } from '../../models/reset-password.model';
import { BasicInfo } from '../../models/basic-info.model';

export interface AtChubbLoginState {
  userDetails: UserDetails;
  news: News;
  setPassword: ResetPassword;
  basicInfo: BasicInfo;
  // somethingFailed: any;
}

export const InitialAtChubbLoginState = <AtChubbLoginState>{};

export const AT_CHUBB_LOGIN_STATE_KEY = 'AT_CHUBB_LOGIN_KEY';
