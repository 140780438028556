import { BrowserModule, Title } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from 'src/environments/environment';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { MsalProviderModule } from '@chubb-auth/msal';
import { ConfigService } from './services/config.service';
import { AngularMaterialModule } from './angular-material.module';
import { UserDetailService } from './services/user-detail.service';
import { ForgotUserService } from './services/forgot-user.service';
import { ForgotPasswordService } from './services/forgot-password.service';
import { GetFaqService } from './services/get-faq.service';
import { ADB2C_CONFIG } from '@chubb-auth/types';
import {
  ImplicitFlowProvider,
  ImplicitFlowProviderModule,
} from '@chubb-auth/implicit';
import { FooterComponent } from './components/footer/footer.component';
import { CriticalMessageComponent } from './components/critical-message/critical-message.component';
import { UnsupportedBrowserComponent } from './components/unsupported-browser/unsupported-browser.component';
import { HeaderComponent } from './components/header/header.component';
import { WrapperComponent } from './components/wrapper/wrapper.component';
// import { LoginModule } from './components/login/login.module';
import { RedirectGuard } from './guards/redirect.guard';
import { HttpClientModule } from '@angular/common/http';
import { AtChubbLoginStoreModule } from './store/at-chubb-login-store.module';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
// import { LoginService } from './services/login.service';
// import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { setaemDataReducer } from './store/reducers/aem-data-reducer';
import { AemDataEffects } from './store/effects/aem-data-effects';
import { setCarouselDataReducer } from './store/reducers/carousel-data-reducer';
import { setIeCriticalmsgDataReducer } from './store/reducers/ieCritical-message-reducer';
import { setCriticalmsgDataReducer } from './store/reducers/critical-message-reducer';

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    CriticalMessageComponent,
    UnsupportedBrowserComponent,
    HeaderComponent,
    WrapperComponent,
  ],
  imports: [
    MsalProviderModule,
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LoggerModule.forRoot({
      level: !environment.production ? NgxLoggerLevel.LOG : NgxLoggerLevel.OFF,
    }),
    FlexLayoutModule,
    // LoginModule,
    AngularMaterialModule,
    ImplicitFlowProviderModule,
    HttpClientModule,
    StoreModule.forRoot({
      aemStoreData: setaemDataReducer,
      getCarouselData: setCarouselDataReducer,
      getCriticalMsg: setCriticalmsgDataReducer,
      getIeCriticalMsg: setIeCriticalmsgDataReducer,
    }),
    EffectsModule.forRoot([AemDataEffects]),
    StoreDevtoolsModule.instrument({
      logOnly: !environment.production,
      maxAge: 10,
    }),
    AtChubbLoginStoreModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    { provide: ADB2C_CONFIG, useValue: environment.adb2cConfig },
    ConfigService,
    // LoginService,
    UserDetailService,
    ForgotUserService,
    ForgotPasswordService,
    GetFaqService,
    ImplicitFlowProvider,
    RedirectGuard,
    Title,
  ],
  exports: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
