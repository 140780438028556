import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AT_CHUBB_LOGIN_STATE_KEY } from './state/at-chubb-login.state';
import { UserDetailsEffects } from './effects/user-details.effects';
import { atChubbLoginReducer } from './reducers/at-chubb-login-reducer';
import { sessionStorageReducer } from './reducers/session-storage-reducer';
import { ConfigService } from '../services/config.service';
import { UserDetailService } from '../services/user-detail.service';
import { ForgotUserService } from '../services/forgot-user.service';
import { ForgotPasswordService } from '../services/forgot-password.service';
import { GetFaqService } from '../services/get-faq.service';
import {AtChubbLoginSelectors} from './selectors/at-chubb-login.selector';
import {ResetPasswordEffects} from './effects/reset-password.effects';
import {BasicInfoEffects} from './effects/basic-info.effects';
import {ForgotUserEffects} from './effects/forgot-user.effects';
import {ForgotPasswordEffects} from './effects/forgot-password.effects';

export const atChubbLoginStoreModule = StoreModule.forFeature(
  AT_CHUBB_LOGIN_STATE_KEY,
  atChubbLoginReducer,
  {
    metaReducers: [sessionStorageReducer],
  }
);
export const AtChubbLoginStoreEffects = EffectsModule.forFeature([
  UserDetailsEffects,
  ResetPasswordEffects,
  BasicInfoEffects,
  ForgotUserEffects,
  ForgotPasswordEffects
]);

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,
    AtChubbLoginStoreEffects,
    atChubbLoginStoreModule,
  ],
  providers: [
    ConfigService,
    UserDetailService,
    ForgotUserService,
    ForgotPasswordService,
    GetFaqService,
    AtChubbLoginSelectors
  ],
})
export class AtChubbLoginStoreModule {}
