import { Action } from '@ngrx/store';

export enum AemDataReadActionsEnums {
  LOAD_AEMDATA = '[AEM] LOAD AEM DATA',
  LOAD_CAROUSEL_DETAILS = '[CAROUSEL] LOAD CAROUSEL DATA',
  LOAD_CRITICAL_MESSAGE = '[CRITICAL] LOAD CRITICAL MESSAGE',
  LOAD_IECRITICAL_MESSAGE = '[IECRITICAL] PAD IECRITICAL MESSAGE',
}

export class LoadAemDataAction implements Action {
  readonly type: string = AemDataReadActionsEnums.LOAD_AEMDATA;
  constructor(public payload: string) {}
}

export class LoadCarouselDetailsAction implements Action {
  readonly type: string = AemDataReadActionsEnums.LOAD_CAROUSEL_DETAILS;
  constructor(public payload: any) {}
}

export class LoadCriticalMessageAction implements Action {
  readonly type: string = AemDataReadActionsEnums.LOAD_CRITICAL_MESSAGE;
  constructor(public payload: any) {}
}

export class LoadIECriticalMessageAction implements Action {
  readonly type: string = AemDataReadActionsEnums.LOAD_IECRITICAL_MESSAGE;
  constructor(public payload: any) {}
}

export type aemDataActionType =
  | LoadAemDataAction
  | LoadCarouselDetailsAction
  | LoadCriticalMessageAction
  | LoadIECriticalMessageAction;
