<ng-container *ngIf="showMessage">
  <div class="critical-message" *ngFor="let item of criticalMessage let i = index">
    <div class="critical-message__spacer"></div>
    <div class="critical-message__content">
      <div class="critical-message__content--text">
        <div class="critical-message__content--text-msgcont">
          <span class="critical-message__content--text-title">
            <img src="assets/icons/warning-icon.svg" alt="warning" class="top-4 critical-message__content--warning">
            {{title}}
          </span>
          <span class="messageSpan"> {{item.Description}} </span>
          <!-- <a (click)="navigateTo(item.external_link)" [ngClass]="{'linkclass': item.external_link}">
            <span>{{item.item}}</span>
          </a> -->
        </div>
      </div>
    </div>
  
    <!-- <div class="critical-message__close" (click)="close()" *ngIf="i==0">
      <img src="assets/icons/icon-close-criticalmessage.svg" alt="close">
    </div> -->
  </div>
</ng-container>