import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map, mergeMap } from 'rxjs/operators';
import { AemDataReadService } from '../../services/aemdataread.service';
import {
  AemDataReadActionsEnums,
  LoadAemDataAction,
  LoadCarouselDetailsAction,
  aemDataActionType,
} from '../actions/aem-data-read-action';

@Injectable()
export class AemDataEffects {
  constructor(
    private _action$: Actions,
    private _aemDataReadService: AemDataReadService,
    private _store: Store
  ) {}
  // GAM-2148: This API is not required for angular app
  /* @Effect()
  getCarouselData$: Observable<any> = this._action$.pipe(
    ofType(AemDataReadActionsEnums.LOAD_AEMDATA),
    mergeMap((action) =>
      this._aemDataReadService.getCarouselData().pipe(
        map((carouselData) => ({
          type: AemDataReadActionsEnums.LOAD_CAROUSEL_DETAILS,
          payload: carouselData,
        }))
      )
    )
  ); */

  getCriticalMessage$: Observable<any> = createEffect(() =>
    this._action$.pipe(
      ofType(AemDataReadActionsEnums.LOAD_AEMDATA),
      mergeMap((action) =>
        this._aemDataReadService.getCriticalMessage().pipe(
          map((criticalMsgData) => ({
            type: AemDataReadActionsEnums.LOAD_CRITICAL_MESSAGE,
            payload: this.transformdata(criticalMsgData),
          }))
        )
      )
    )
  );
  //   @Effect()
  //   getIeCriticalMessage$: Observable<any> = this._action$.pipe(
  //     ofType(AemDataReadActionsEnums.LOAD_AEMDATA),
  //   mergeMap((action) =>
  //   this._aemDataReadService.getIeCriticalMessage().pipe(
  //     map((criticalMsgData) => ({
  //         type: AemDataReadActionsEnums.LOAD_IECRITICAL_MESSAGE,
  //         payload: this.transformdata(criticalMsgData),
  //         })),
  //         )
  // )
  //   );

  transformdata(criticalMsgData) {
    let iMessage = criticalMsgData.replace('newsHandler(', '');
    iMessage = JSON.parse(iMessage.replace('] })', '] }'));
    criticalMsgData = iMessage.items.filter((data) => {
      return data.Description !== '';
    });
    return criticalMsgData;
  }
}
