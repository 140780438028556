import {
  AtChubbLoginState,
  InitialAtChubbLoginState,
} from '../state/at-chubb-login.state';
import {
  AtChubbLoginActionsEnums,
  AtChubbLoginActionsTypes,
  SomethingFailed,
} from '../actions/at-chubb-login-actions';
import { userDetailsSuccessReducer } from './user-details-reducer';
import { newsSuccessReducer } from './news-reducer';
import { resetPasswordSuccessReducer } from './reset-password-reducer';
import { basicInfoSuccessReducer } from './basic-info-reducer';
import { somethingFailedReducer } from './something-failed-reducer';

export interface ReducerFunctions {
  [actionType: string]: (
    state: AtChubbLoginState,
    action: AtChubbLoginActionsTypes
  ) => AtChubbLoginState;
}

const reducerFunctions: ReducerFunctions = {
  [AtChubbLoginActionsEnums.LOAD_USER_DETAILS_SUCCESS]: userDetailsSuccessReducer,
  [AtChubbLoginActionsEnums.GET_NEWS_SUCCESS]: newsSuccessReducer,
  [AtChubbLoginActionsEnums.RESET_PASSWORD_SUCCESS]: resetPasswordSuccessReducer,
  [AtChubbLoginActionsEnums.BASIC_INFO_SUCCESS]: basicInfoSuccessReducer,
  // [AtChubbLoginActionsEnums.SOMETHING_FAILED]: somethingFailedReducer
};
// DO NOT MODIFY THE REDUCER BELOW. USE THE REDUCER FUNCTIONS MAP INSTEAD
export function atChubbLoginReducer(
  state = InitialAtChubbLoginState,
  action: AtChubbLoginActionsTypes
): AtChubbLoginState {
  if (action.type in reducerFunctions) {
    return reducerFunctions[action.type](state, action);
  } else {
    return {
      ...state,
    };
  }
}
