import { AtChubbLoginState } from '../state/at-chubb-login.state';
import { ResetPasswordSuccessAction } from '../actions/at-chubb-login-actions';

export function resetPasswordSuccessReducer(
  currentState: AtChubbLoginState,
  action: ResetPasswordSuccessAction
): AtChubbLoginState {
  return {
    ...currentState,
    setPassword: action.resetPasswordResponse,
  };
}
