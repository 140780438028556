<div class="unsupported-browser">
  <div class="unsupported-browser__spacer"></div>
  <div class="unsupported-browser__content">
    <img src="assets/icons/icon-warning-orange.svg" alt="warning" class="unsupported-browser__content--warning">
    <div class="unsupported-browser__content--text">
      <p>
        Microsoft recently announced that they
        <a href="https://techcommunity.microsoft.com/t5/microsoft-365-blog/microsoft-365-apps-say-farewell-to-internet-explorer-11-and/ba-p/1591666" target="_blank">
          <u>will stop supporting Internet Explorer 11 (IE 11)</u>
        </a>.
        Beginning July 17th, Chubb applications will no longer support internet explorer versions older than IE 11. For an optimal experience, we recommend using the most recent version of Chrome, Microsoft Edge, Firefox, or Safari. If you have any questions, please <a [routerLink]="['/help-faq']" target="_blank">contact us.</a>
      </p>
    </div>
  </div>
</div>
