import { AtChubbLoginState } from '../state/at-chubb-login.state';
import { LoadUserDetailsSuccessAction } from '../actions/at-chubb-login-actions';

export function userDetailsSuccessReducer(
  currentState: AtChubbLoginState,
  action: LoadUserDetailsSuccessAction
): AtChubbLoginState {
  return {
    ...currentState,
    userDetails: action.userDetails,
  };
}
